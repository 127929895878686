import { PostMessageEvent } from '../constants/PostMessages';
import { getHostNameFromQueryParams } from './HostName';

export const sendPostMessage = <T>(
    event: PostMessageEvent,
    data: T | null = null,
    hostName = getHostNameFromQueryParams(),
): void => {
    if (!hostName) {
        return;
    }

    if (window === window.parent) {
        hostName = window.location.origin
    }

    window.parent.postMessage(
        {
            event,
            data,
        },
        hostName,
    );
};
